import * as v from 'valibot';

const form = {
  optional<S extends v.BaseSchema<any, any, any>>(schema: S) {
    return v.pipe(
      v.any(),
      v.transform((v) =>
        (typeof v === 'string' && v.length > 0) || (typeof v !== 'string' && v !== null && v !== undefined)
          ? v
          : undefined,
      ),
      v.optional(schema),
    );
  },
  phone: v.pipe(
    v.string('Please enter a phone number.'),
    v.check((v) => v.match(/\+?1?\d{10}/) !== null, 'Please enter a 10 digit phone number with no spaces or dashes.'),
    v.transform((v) => (v.startsWith('+1') ? v : `+1${v}`) as TPhone),
    v.check((v) => v.match(/\+1\d{10}/) !== null, 'Must be a valid phone number (starting with +1, eg: +19876543210).'),
  ),
  number() {
    return v.pipe(
      v.union([v.string(), v.number()]),
      v.transform((value) =>
        typeof value === 'string' && value.match(/^[0-9]+(\.[0-9]+)?$/) ? parseFloat(value) : value,
      ),
      v.check((value) => typeof value === 'number' && !isNaN(value), 'Must be a number.'),
      v.transform((value) => value as number),
    );
  },
  boolean: v.pipe(
    v.union([v.boolean(), v.literal('true'), v.literal('false'), v.literal(''), v.undefined()]),
    v.transform((value) => value === true || value === 'true'),
  ),
  money({ positive = false }: { positive?: boolean } = {}) {
    const pipe = v.pipe(
      v.string('Please enter a dollar amount.'),
      v.check(
        (v) => v.match(/^-?\d+(\.\d{1,2})?$/) !== null,
        'Must be a valid dollar amount (eg: 19.99) without a currency sign.',
      ),
      v.transform((v) => v as TMoney),
    );

    if (positive) {
      return v.pipe(
        pipe,
        v.check((v) => parseFloat(v) > 0, 'Must be a positive dollar amount.'),
      );
    }

    return pipe;
  },
  email: v.pipe(
    v.string('Please enter an email address.'),
    v.trim(),
    v.toLowerCase(),
    v.email('The email address provided is not valid.'),
    v.transform((v) => v as TEmail),
  ),
};

export default form;
