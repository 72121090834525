import { DateTime } from 'luxon';

export function holiday_lookup(date: DateTime | TDateISODate): Holiday[] {
  return holiday_days[DateTime.isDateTime(date) ? date.toSQLDate() : date] ?? [];
}

export enum Holiday {
  NEW_YEARS = "New Year's Day",
  NEW_YEARS_EVE = "New Year's Eve",
  CHRISTMAS = 'Christmas Day',
  CHRISTMAS_EVE = 'Christmas Eve',
  THANKSGIVING = 'Thanksgiving Day',
  DAY_AFTER_THANKSGIVING = 'Day After Thanksgiving',
  VETERANS_DAY = 'Veterans Day',
  ELECTION = 'Election Day',
  MLK = 'Martin Luther King Jr. Day',
  VALENTINES_DAY = "Valentine's Day",
  GOOD_FRIDAY = 'Good Friday',
  PALM_SUNDAY = 'Palm Sunday',
  ASH_WEDNESDAY = 'Ash Wednesday',
  EASTER = 'Easter',
  EASTER_MONDAY = 'Easter Monday',
  ST_PATRICKS_DAY = "Saint Patrick's Day",
  MOTHERS_DAY = "Mother's Day",
  MEMORIAL_DAY = 'Memorial Day',
  FATHERS_DAY = "Father's Day",
  JULY_4TH = 'Independence Day',
  JUNTEENTH = 'Juneteenth National Independence Day',
  LABOR_DAY = 'Labor Day',
  COLUMBUS_DAY = 'Columbus Day',
  HALLOWEEN = 'Halloween',
}

// generated using output of transform.ts
const holiday_days: Record<TDateISODate, Holiday[]> = {
  '2024-01-01': [Holiday.NEW_YEARS],
  '2024-01-15': [Holiday.MLK],
  '2024-02-14': [Holiday.ASH_WEDNESDAY, Holiday.VALENTINES_DAY],
  '2024-03-17': [Holiday.ST_PATRICKS_DAY],
  '2024-03-24': [Holiday.PALM_SUNDAY],
  '2024-03-29': [Holiday.GOOD_FRIDAY],
  '2024-03-31': [Holiday.EASTER],
  '2024-04-01': [Holiday.EASTER_MONDAY],
  '2024-05-12': [Holiday.MOTHERS_DAY],
  '2024-05-27': [Holiday.MEMORIAL_DAY],
  '2024-06-16': [Holiday.FATHERS_DAY],
  '2024-06-19': [Holiday.JUNTEENTH],
  '2024-07-04': [Holiday.JULY_4TH],
  '2024-09-02': [Holiday.LABOR_DAY],
  '2024-10-14': [Holiday.COLUMBUS_DAY],
  '2024-10-31': [Holiday.HALLOWEEN],
  '2024-11-05': [Holiday.ELECTION],
  '2024-11-11': [Holiday.VETERANS_DAY],
  '2024-11-28': [Holiday.THANKSGIVING],
  '2024-11-29': [Holiday.DAY_AFTER_THANKSGIVING],
  '2024-12-24': [Holiday.CHRISTMAS_EVE],
  '2024-12-25': [Holiday.CHRISTMAS],
  '2024-12-31': [Holiday.NEW_YEARS_EVE],
  '2025-01-01': [Holiday.NEW_YEARS],
  '2025-01-20': [Holiday.MLK],
  '2025-02-14': [Holiday.VALENTINES_DAY],
  '2025-03-05': [Holiday.ASH_WEDNESDAY],
  '2025-03-17': [Holiday.ST_PATRICKS_DAY],
  '2025-04-13': [Holiday.PALM_SUNDAY],
  '2025-04-18': [Holiday.GOOD_FRIDAY],
  '2025-04-20': [Holiday.EASTER],
  '2025-04-21': [Holiday.EASTER_MONDAY],
  '2025-05-11': [Holiday.MOTHERS_DAY],
  '2025-05-26': [Holiday.MEMORIAL_DAY],
  '2025-06-15': [Holiday.FATHERS_DAY],
  '2025-06-19': [Holiday.JUNTEENTH],
  '2025-07-04': [Holiday.JULY_4TH],
  '2025-09-01': [Holiday.LABOR_DAY],
  '2025-10-13': [Holiday.COLUMBUS_DAY],
  '2025-10-31': [Holiday.HALLOWEEN],
  '2025-11-04': [Holiday.ELECTION],
  '2025-11-11': [Holiday.VETERANS_DAY],
  '2025-11-27': [Holiday.THANKSGIVING],
  '2025-11-28': [Holiday.DAY_AFTER_THANKSGIVING],
  '2025-12-24': [Holiday.CHRISTMAS_EVE],
  '2025-12-25': [Holiday.CHRISTMAS],
  '2025-12-31': [Holiday.NEW_YEARS_EVE],
  '2026-01-01': [Holiday.NEW_YEARS],
  '2026-01-19': [Holiday.MLK],
  '2026-02-14': [Holiday.VALENTINES_DAY],
  '2026-02-18': [Holiday.ASH_WEDNESDAY],
  '2026-03-17': [Holiday.ST_PATRICKS_DAY],
  '2026-03-29': [Holiday.PALM_SUNDAY],
  '2026-04-03': [Holiday.GOOD_FRIDAY],
  '2026-04-05': [Holiday.EASTER],
  '2026-04-06': [Holiday.EASTER_MONDAY],
  '2026-05-10': [Holiday.MOTHERS_DAY],
  '2026-05-25': [Holiday.MEMORIAL_DAY],
  '2026-06-19': [Holiday.JUNTEENTH],
  '2026-06-21': [Holiday.FATHERS_DAY],
  '2026-07-04': [Holiday.JULY_4TH],
  '2026-09-07': [Holiday.LABOR_DAY],
  '2026-10-12': [Holiday.COLUMBUS_DAY],
  '2026-10-31': [Holiday.HALLOWEEN],
  '2026-11-03': [Holiday.ELECTION],
  '2026-11-11': [Holiday.VETERANS_DAY],
  '2026-11-26': [Holiday.THANKSGIVING],
  '2026-11-27': [Holiday.DAY_AFTER_THANKSGIVING],
  '2026-12-24': [Holiday.CHRISTMAS_EVE],
  '2026-12-25': [Holiday.CHRISTMAS],
  '2026-12-31': [Holiday.NEW_YEARS_EVE],
  '2027-01-01': [Holiday.NEW_YEARS],
  '2027-01-18': [Holiday.MLK],
  '2027-02-10': [Holiday.ASH_WEDNESDAY],
  '2027-02-14': [Holiday.VALENTINES_DAY],
  '2027-03-17': [Holiday.ST_PATRICKS_DAY],
  '2027-03-21': [Holiday.PALM_SUNDAY],
  '2027-03-26': [Holiday.GOOD_FRIDAY],
  '2027-03-28': [Holiday.EASTER],
  '2027-03-29': [Holiday.EASTER_MONDAY],
  '2027-05-09': [Holiday.MOTHERS_DAY],
  '2027-05-31': [Holiday.MEMORIAL_DAY],
  '2027-06-19': [Holiday.JUNTEENTH],
  '2027-06-20': [Holiday.FATHERS_DAY],
  '2027-07-04': [Holiday.JULY_4TH],
  '2027-09-06': [Holiday.LABOR_DAY],
  '2027-10-11': [Holiday.COLUMBUS_DAY],
  '2027-10-31': [Holiday.HALLOWEEN],
  '2027-11-02': [Holiday.ELECTION],
  '2027-11-11': [Holiday.VETERANS_DAY],
  '2027-11-25': [Holiday.THANKSGIVING],
  '2027-11-26': [Holiday.DAY_AFTER_THANKSGIVING],
  '2027-12-24': [Holiday.CHRISTMAS_EVE],
  '2027-12-25': [Holiday.CHRISTMAS],
  '2027-12-31': [Holiday.NEW_YEARS_EVE],
  '2028-01-01': [Holiday.NEW_YEARS],
};
